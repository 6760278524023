//
// This file is for classes added to make modifications to existing Bootstrap code that there is no Sass variable to adjust
// ----------------

// Naming convention for alternative version of Bootstrap components .act-mods-<bootstrap class>
// Example: .breadcrumb => .act-mods-breadcrumb


.nav-link:hover, 
.nav-link:focus {
    background-color: #eee;
    color: #000;
}

.btn-success:hover,
.btn-success:focus {
    background: darken($green, 13%);
}