// -------------------------
// Custom CSS Styles
// -------------------------

// This is needed to have text bolded properly
// Reference: https://stackoverflow.com/questions/37793086/why-is-the-font-style-bold-not-correctly-applied-in-chrome-on-this-website
body {
    -webkit-font-smoothing: antialiased;
}

// We want the p text to be the equalivent of 16px but the other elements to take the bootstrap variable of $font-size-base: 0.9rem; for the scaling of component
p {
    font-size: 1rem;
}

nav {
    font-size: 0.9rem;
}


// Bootstrap for some reason sets position of caption from top to bottom
caption {
    caption-side: top;
    @extend .h3; 
    color: #212529;
}

.settings-heading {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

#riddle-slide {
  position: relative;
  top: 0;
  left: 0;
}

/* Simple fade transition to give a real sense */
/* of change to our component replacements     */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

/* Custom grid classes for the ballots results page because I got tired of
 * trying to do this using bootstrap classes).
 */
.ballot-grid {
  display: grid;
  grid-gap: $spacer;
  @include media-breakpoint-up(lg) {
    grid: auto / repeat(2, calc(50% - #{$spacer/2}));
  }

  > .ballot {
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $spacer;
  }
}
